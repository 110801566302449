<script setup lang="ts">
// wraps base Input component for use within forms
// automatically handles validation, display of error messages, etc.
import { useField } from "vee-validate"
import Input from "@/components/V/Input.vue"

interface Props {
  name: string // primary key for field within the form
  showErrorMessage?: boolean
  // inherited from Input
  autocomplete?:
    | "off"
    | "on"
    | "name"
    | "email"
    | "new-password"
    | "current-password"
    | "organization"
    | "one-time-code"
  background?: "slate" | "white" | undefined
  description?: string | undefined // hint text
  disabled?: boolean
  groupPosition?: "left" | "middle" | "right" | undefined
  label?: string | undefined
  placeholder?: string
  size?: "small" | "medium" | "large"
  spellcheck?: boolean // optional spellcheck attribute
  type?: "text" | "number" | "email" | "password" | "search" | "url"
  tooltip?: string | undefined // adds tooltip to description
}

const props = withDefaults(defineProps<Props>(), {
  showErrorMessage: true,
  // inherited from Input
  autocomplete: "off",
  background: "slate",
  description: undefined,
  disabled: false,
  groupPosition: undefined,
  label: undefined,
  placeholder: "",
  size: "medium",
  spellcheck: false,
  type: "text",
  tooltip: undefined,
})

defineExpose({ focus })

const input = ref<InstanceType<typeof Input> | null>(null)

// use name to look up field data in parent form schema
const { value, errorMessage, handleBlur } = useField<string>(() => props.name)

// focus native input
function focus() {
  input.value?.focus()
}
</script>

<template>
  <Input
    ref="input"
    v-model="value"
    :autocomplete="autocomplete"
    :background="background"
    :description="description"
    :disabled="disabled"
    :error-message="showErrorMessage ? errorMessage : ''"
    :invalid="!!errorMessage"
    :group-position="groupPosition"
    :label="label"
    :name="name"
    :placeholder="placeholder"
    :size="size"
    :spellcheck="spellcheck"
    :tooltip="tooltip"
    :type="type"
    @blur="handleBlur"
  />
</template>
